<template>
<v-container fluid class="down-top-padding">
    <v-breadcrumbs large :items="breadcrumbs"></v-breadcrumbs
    >
    <v-card>
      <v-card-title>
        Пользователи
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchString"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        loading-text="Загрузка... Пожалуйста подождите"
        :loading="loading"
        :page.sync="page"
        disable-sort
        :server-items-length="count"
        :items-per-page.sync="perPage"
        no-data-text="Результатов нет"
        no-results-text="Результатов нет"
        :footer-props="{
          'items-per-page-text': 'Количество',
          'items-per-page-options': [5, 10, 20],
        }"
      >
        <template
          v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }}-{{ pageStop }} из {{ itemsLength }}
        </template>
        <template v-slot:no-data> Нет элементов </template>
        <template v-slot:item.avatar="{ item }">
          <div class="d-flex align-center">
              <v-avatar size="48" class="my-3">
                <v-img
                  v-if="item.avatar"
                  :src="$http.defaults.apiURL + item.avatar"
                ></v-img>
                <i v-else width="48px" class="fa fa-3x fa-user-circle"></i>
              </v-avatar>
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          <div
            class="d-flex align-center"
            style="cursor: pointer"
            @click="userEdit(item)"
          >
            {{ item.surname }} <br />{{ item.name }} <br />{{
              item.patronymic
            }}
          </div>
        </template>
        <template v-slot:item.email="{ item }">
          <div class="d-flex align-center">
            Email: {{ item.email }}<br />
            Телефон: {{ item.phone }} <br />
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="userEdit(item)"><v-icon>mdi-cog</v-icon></v-btn>
          <v-btn icon @click="login(item.user_id)"
            ><v-icon>mdi-incognito</v-icon></v-btn
          >
        </template>
        <template v-slot:item.access="{ item }">
          <v-btn
            icon
            @click="lock(item)"
            v-if="item.blocked_at"
            color="red"
            ><v-icon>mdi-lock-outline</v-icon></v-btn
          >
          <v-btn
            icon
            @click="lock(item)"
            v-if="!item.blocked_at"
            color="green"
            ><v-icon>mdi-lock-open-outline</v-icon></v-btn
          >

          <v-btn
            icon
            @click="
              currentUser = item;
              removeModal = true;
            "
            class="ma-1"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
        <template v-slot:item.confirmed="{ item }">
          <center>
            <span v-if="item.confirmed_at">{{
              parseInt(item.confirmed_at) | moment("d/MM/YYYY, H:mm:ss")
            }}</span>
            <span v-else
              >Не подтвержден<br /><v-btn @click="confirm(item)" small
                >Подтвердить</v-btn
              ></span
            >
          </center>
        </template>
        <template v-slot:loading>
      <v-skeleton-loader
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog ref="settings" v-model="userModal">
      <settings
      @close="userModal=false"
        v-if="currentUser && currentUser.user_id"
        @user-update="updateUser($event)"
        :userId="currentUser.user_id"
      ></settings>
    </v-dialog>
    <v-dialog
      ref="remove_modal"
      width="600px"
      v-model="removeModal"
      title="Удаление"
    >
      <v-card>
        <v-card-text class="pa-3">
          <h3>
            Вы действительно хотите удалить этого пользователя? Это действие
            необратимо.
          </h3>
          <v-btn class="mt-3" color="danger" block @click="remove(currentUser)"
            >Удалить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
</v-container>
</template>
<script>
import settings from "../profile/settings";
const debounce = function (f, ms) {
  let timer = null;
  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(onComplete, ms);
  };
};
export default {
  name: "users_list",
  components: {
    settings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Главная",
          disabled: false,
          to: "/",
        },
        {
          text: "Пользователи",
          disabled: true,
          to: "/users",
        },
      ],
      headers: [
        { text: "ИД", value: "user_id" },
        { text: "Аватар", value: "avatar" },
        { text: "ФИО", value: "name" },
        { text: "Контакты", value: "email" },
        { text: "Действия", value: "actions" },
        { text: "Доступ", value: "access" },
        { text: "Подтерждён", value: "confirmed" },
      ],
      perPage: 10,
      page: 1,
      count: 0,
      orderBy: "user_id",
      searchString: "",
      items: [],
      currentUser: {},
      removeModal: false,
      userModal: false,
      loading: true
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get("admin/users/get", {
          params: {
            page: this.page,
            per_page: this.perPage,
            query: this.searchString,
          },
        })
        .then((response) => {
          this.items = response.data.profile;
          this.count = parseInt(response.data.count);
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(item) {
      let index = this.items.findIndex((el) => el.user_id == item.user_id);
      this.$http
        .get("admin/users/remove", {
          params: {
            id: item.user_id,
          },
        })
        .then(() => {
          // JSON responses are automatically parsed.
          this.items.splice(index, 1);
          this.removeModal = false;
        })
        .catch(() => {
          alert("Возникла ошибка");
        });
    },
    updateUser(event) {
      let index = this.items.findIndex(
        (el) => el.user_id == this.currentUser.user_id
      );
      Object.assign(this.items[index], event);
      this.userModal = false;
    },
    lock(item) {
      let index = this.items.findIndex((el) => el.user_id == item.user_id);
      this.$http
        .get("admin/users/lock", {
          params: {
            id: item.user_id,
          },
        })
        .then((response) => {
          // JSON responses are automatically parsed.
          this.$set(this.items[index], "blocked_at", response.data);
        })
        .catch(() => {
          alert("Возникла ошибка");
        });
    },
    confirm(item) {
      let index = this.items.findIndex((el) => el.user_id == item.user_id);
      this.$http
        .get("admin/users/confirm", {
          params: {
            id: item.user_id,
          },
        })
        .then((response) => {
          // JSON responses are automatically parsed.
          this.$set(this.items[index], "confirmed_at", response.data);
        })
        .catch(() => {
          alert("Возникла ошибка");
        });
    },
    userEdit(user) {
      this.currentUser = user;
      this.userModal = true;
    },
    login(user_id) {
      this.$http
        .get("auth/spy-login?user_id=" + user_id)
        .then(() => {
          window.location.href = "/";
        })
        .catch((e) => {
          this.error = e.response.data;
        });
    },
    search: debounce(function () {
      this.fetchData();
    }, 500),
  },
  watch: {
    page() {
      this.fetchData();
    },
    perPage() {
      this.fetchData();
    },
    searchString() {
      this.page = 1;
      this.search();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>